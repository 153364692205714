
import { ref, getCurrentInstance } from 'vue';
import MediaQueryManager from 'o365.vue.components.MediaQueryManager.ts';
import { flutterHapticFeedback } from "apps.modules.FlutterJavaScriptChannels.ts";

declare global {
    interface Window {
        FlutterAppCenterAnalytics?: {
            postMessage: (message: string) => void;
        };
        FlutterHapticFeedback?: {
            postMessage: (message: string) => void;
        };
    }
}

export const useScope = ref(window?.__navBarData?.useScope ?? false); 

/* util for responsive design */

export const isPWA = ref(MediaQueryManager.getQueryMatchState("isPWA") ?? false);
export const isMobile = ref(MediaQueryManager.getQueryMatchState("isMobile") ?? false);
export const isTablet = ref(MediaQueryManager.getQueryMatchState("isTablet") ?? false);
export const isDesktop = ref(MediaQueryManager.getQueryMatchState("isDesktop") ?? false);
export const isMobileApp = ref(false);

function updateQueries(queryName: string, queryResult: boolean) {
    switch (queryName) {
        case 'isPWA':
            isPWA.value = queryResult;
            break;
        case 'isMobile':
            isMobile.value = queryResult;
            break;
        case "isTablet":
            isTablet.value = queryResult;
            break;
        case "isDesktop":
            isDesktop.value = queryResult;
            break;
    }
}

MediaQueryManager.on('QueryChanged', updateQueries);



/* util for checking VueRouter */
//export const isVueRouter = ref(false);

let savedRouter;

export function setVueRouter(obj) {
    savedRouter = obj;
}

export const getVueRouter = () => {
    if (savedRouter) {
        return savedRouter;
    } else {
        return getCurrentInstance()?.appContext?.config?.globalProperties?.$router;
    }
};

export function getVueRoute() {
    return getVueRouter()?.currentRoute?.value;
}





/* general util */

export async function resolveMinDelay(promise : Promise<any>, delay : number) {
    await new Promise(resolve => setTimeout(resolve, delay));
    return promise;
}

export function combineWhereClauses(clauses : string[], join: string = "AND") {
    return clauses.map(e => `(${e})`).join(` ${join} `);
}

export function sendEventToAppCenter(eventName: string, eventProperties: any = {}) {
    const route = getVueRoute();
    if (route) {
        const appID = route.meta?.id;
        if (appID) {
            eventName = appID + " :: " + eventName;
        }
    }
    /*
    const router = getVueRouter();
    if (router) {
        const appID = router?.currentRoute?.value?.meta?.id;
        if (appID) {
            eventName = appID + " :: " + eventName;
        }
    }
    */
    console.log("sendEventToAppCenter", eventName, eventProperties);
    window.FlutterAppCenterAnalytics?.postMessage(  
        JSON.stringify({
            "eventName": eventName, 
            "eventProperties": eventProperties,
        })
    );
}

/*
// Using this class: https://api.flutter.dev/flutter/services/HapticFeedback-class.html
export function flutterHapticFeedback(hapticFeedbackType: 'HeavyImpact' | 'LightImpact' | 'MediumImpact' | 'SelectionClick' | 'Vibrate') {
    window.FlutterHapticFeedback?.postMessage(hapticFeedbackType);
}
*/

const impactDelay = 100;

function sequentialImpact(impactTypes : string[]) {
    for (let [i, impactType] of Object.entries(impactTypes)) {
        setTimeout(() => flutterHapticFeedback(impactType), impactDelay*i);
    }
}

export function hapticsImpact(impactType : string) {
    if (impactType === "Success") {
        //sequentialImpact(["LightImpact", "LightImpact", "HeavyImpact"]);
        sequentialImpact(["LightImpact", "MediumImpact", "HeavyImpact"]);
    } else if (impactType === "Error") {
        //sequentialImpact(["HeavyImpact", "HeavyImpact", "LightImpact"]);
        sequentialImpact(["HeavyImpact", "MediumImpact", "LightImpact"]);
    } else if (impactType === "Warning") {
        //sequentialImpact(["MediumImpact", "MediumImpact"]);
        setTimeout(() => flutterHapticFeedback("MediumImpact"), 0);
        setTimeout(() => flutterHapticFeedback("MediumImpact"), 150);
    } else {
        flutterHapticFeedback(impactType);
    }
}

export { flutterHapticFeedback }


export function getQueryParam(key) {
    const router = getVueRouter();
    if (router) {
        return router.currentRoute.value.query[key];
    } else {
        return new URLSearchParams(window.location.search).get(key);
    }
}

export function getQueryParams() {
    const router = getVueRouter();
    if (router) {
        return {};
    } else {
        return {};
    }
}




